<template>
  <!-- 准考证规则弹窗 -->
  <b-modal id="addMould" v-model="showmodal" centered title="准考证规则说明" size="lg" title-class="font-18" hide-footer>
    <div>
      <div class="flexList mb-2">
        <input placeholder="请输入模板标题搜索" v-model="pageData.mbmc" class=" w-30  form-control h30 mr-2" type="text"
          clearable>
        <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
            class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
        <a href="/admin/baseMould" target="_blank">
          <b-button variant="outline-info" class="flexList mr-2 condition h30">前往维护</b-button>
        </a>
      </div>
      <table class="table  light-table table-hover  table-bordered text-center">
        <thead class="thead-light">
          <tr>
            <th>选择</th>
            <th style="width: 10%;">序号</th>
            <th>标题</th>
            <th>模板类别</th>
            <th>添加时间</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in moldList" :key="index">
            <td>
              <input type="checkbox" :value="item" v-model="chooseModel">
            </td>
            <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
            <td>{{item.mbmc}}</td>
            <td>{{item.mblb}}</td>
            <td> {{ item.createTime }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mb-2 text-right">
        <el-pagination @size-change="handleSizeChange" background small @current-change="handleCurrentChange"
          :current-page="pageData.pageNum" :page-size="pageData.pageSize" layout="prev, pager, next"
          :total="pageData.total">
        </el-pagination>
      </div>
    </div>
    <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
        @click="addFun()">确定</button>
      <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
    </div>



  </b-modal>
</template>

<script>
  import {
    getNoticeList,
  } from "@/api/admin/base/mould.js"
  export default {
    props: {
      flbm: String,
      examForm: Object
    },
    data() {
      return {
        chooseModel: [],
        moldList: [],
        showmodal: false,
        pageData: { //模板分页
          pageNum: 1,
          pageSize: 10,
          total: 0
        },
      }
    },
    methods: {
      // 基础模板
      getMouldList(params) {
        let mblb = ""
        if (this.mc == "zkzsmmc") {
          mblb = "准考证"
        } else {
          mblb = "准考证告知承诺"
        }

        getNoticeList({
          ...this.pageData,
          gllbdm: params,
          mblb: mblb,
          qyzt: 1
        }).then(res => {
          if (res.status) {
            this.moldList = res.data
            this.pageData.total = res.total
            if (this.mc == "zkzsmmc") {
              let newMbdm = this.examForm.zkzsmh.split(',')
              if (newMbdm) {
                this.showCheck(newMbdm)
              }
            } else if (this.mc == "zkzgzcnmc") {
              let zskGzcn = this.examForm.zkzgzcnh.split(",")
              if (zskGzcn) {
                this.showCheck(zskGzcn)
              }
            }

          }
        })
      },
      // 回显判断
      showCheck(type) {
        type.forEach(v => {
          let hasChecked = this.moldList.filter(x => {
            return x.mbbm == v
          })[0]
          if (hasChecked) {
            this.chooseModel = []
            this.chooseModel.push(hasChecked)
          }
        })
      },


      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getMouldList()
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getMouldList()
      },
      // 模板
      check(mc, dm) {
        this.mc = mc;
        this.dm = dm;
        this.showmodal = true
        this.chooseModel = []
        this.getMouldList(this.flbm)

      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getMouldList() //渲染数据的函数
      },
      // 切换基础模板
      addFun() {
        let box = JSON.parse(JSON.stringify(this.chooseModel))
        let str = ''
        let sid = ''
        box.forEach(k => {
          str += k.mbmc + ','
          sid += k.mbbm + ','

        })
        if (str) {
          str = str.slice(0, -1)
          sid = sid.slice(0, -1)
        }
        this.$emit('addFun', str, sid, this.mc, this.dm)
        this.showmodal = false


      },

    }

  }
</script>

<style>
</style>
