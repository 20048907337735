<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style scoped>
  .w20 {
    width: 20%;
  }

  .w20:last-child {
    width: 19%;
  }

  .w10 {
    width: 10%;
  }

  .w25 {
    width: 25%;
  }

  .w33 {
    width: 33%;
  }

  .w50 {
    width: 50%;
  }

  .w66 {
    width: 66%;
  }

  .w-99 {
    width: 99%;
  }

  .w100 {
    width: 100%;
  }


  .guding-top {
    width: 80%;
  }

  .top-height {
    max-height: 160px;
    line-height: 160px
  }

  .cjd_title {
    width: 100%;
    min-height: 50px;
    line-height: 30px;
    text-align: center;
    border: 1px dashed rgb(187, 187, 187);
    font-size: 16px;
    color: #000000;
  }

  .less-width .w20 {
    width: 19.8%;
  }

  .less-width .w33 {
    width: 33.3%;
  }


  .less-width .w50 {
    width: 40.5%;
  }

  .less-width .w50:nth-child(2n) {
    width: 59.5% !important;
  }

  .dragArea {
    padding: 30px 0;
    display: flex;
  }

  .msgContent {
    border: unset;
  }


  .msgName {
    width: 120px;
  }

  .grey-time {
    width: 36%;
  }

  .heighLabel {
    height: 132px;
    line-height: 132px;
  }

  .msgLabel {
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    height: 40px;
    line-height: 40px;
  }

  .border-diff .msgLabel {
    border-right: unset;
  }

  .msgContent.border-diff .msgLabel:nth-child(n+5) {
    border-bottom: unset;

  }

  .less-width .msgLabel {
    border-bottom: unset;
  }

  .d-border-bottom {
    border-bottom: 1px solid #DFDFDF;
  }

  .canmove {
    cursor: move;
  }

  .msgContent .msgLabel {
    border-top: unset;
  }

  .msgVal {
    padding: 0 10px;
  }

  /* .msgContent .msgVal {
    border-top: unset;
  } */


  .list-group-item {
    background-color: rgb(255, 252, 239);
    cursor: pointer;
  }

  /deep/ .el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210)
  }

  .guding-col {
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .guding-col .msgLabel:nth-child(n-1) {
    border-bottom: unset;
  }

  .border-top {
    border-top: 1px solid #DFDFDF !important;
  }

  .border-right {
    border-right: 1px solid #DFDFDF !important;
  }

  .guding-img {
    width: 19%;
    height: 161px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFDFDF;
  }

  @media screen and (min-width: 1200px) {
    .msgName {
      min-width: 120px;
      width: 120px;
    }
  }


  .auto-scroll {
    overflow-y: auto;
  }

  /deep/.el-radio-group label {
    margin-bottom: 0;
  }

  .notice {
    overflow-y: auto;
  }

  .closeIcon .iconfont {
    font-size: 18px;
  }

  .closeIcon .iconfont:hover {
    color: red;
  }

  .content-mould {
    padding: 0;
    height: 310px;

  }

  /deep/.form-select {
    padding-top: 4px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import draggable from "vuedraggable";
  import YzSelect from "@/components/form/yzSelect.vue";
  import PrintRules from "@/views/pages/admin/components/printRules.vue";
  import printCardHeader from "./printCard-header";
  import $ from "jquery"
  import {
    getExamDetails,
  } from "@/api/admin/exam/examRecord.js"
  import {
    editZkz,
    getZkzInfo,
  } from "@/api/printCard/printCard.js"
  import {
    saveScorTemplate,
    getTemplateInfo,
    editTemplate,
    TemplateEasyList,
    deleteModule
  } from "@/api/score/template.js";
  import {
    getUploadLimit,
    checkFile,
    uploadFiles,
    exportItem,
    getCustomFileds
  } from "@/api/common/public.js";


  export default {
    name: "clone-on-control",
    display: "Clone on Control",
    instruction: "Press Ctrl to clone element from list 1",
    order: 4,
    components: {
      draggable,
      YzSelect,
      Layout,
      CheckHeader,
      printCardHeader,
      PrintRules
    },
    data() {
      return {
        title: "准考证库导入 ",
        title2: "2021年二级造价工程师职业资格增报专业考试准考证",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "准考证库导入",
            active: true
          }
        ],
        sid: "",
        styId: "",
        styMbmc: "",
        isImport: false,
        form: {},
        chooseId: {},
        moudleList: [],
        isSaveMold: false,
        // 文件上传
        uploadStatus: "",
        uploadModule: "Ticket",
        isChangeHxbj: false,
        fileUrl: "",
        uploadLimit: {
          ext: "",
          size: 0
        },
        isShowZp: true, //是否显示照片
        fileUp: false,
        mid: "",
        mbmc: "",
        imgId: "",
        list1: [],
        sysFields: [],
        list2: [],
        list3: [],
        list4: [],
        ksbmbh: "",
        module: "ZKZK",
        ksbmb: "",
        keyword: "",
        hasMbmc: false,
        listChoose: [],
        isChange: false,
        isDraggle: false,
        controlOnStart: true,
        showComp: false,
        examFormObj: {},
        examForm: {
          zkzmc: "",
          zkzzysx: "",
        },
        sfhxbj: false,
        sfxsewm:true,
        sfdycns: 0,
        minHeight: 0,
        flbm: "",
        zdymc: "",
        isChangeName: false


      };
    },
    methods: {
      // 获取准考证字段
      getFileds() {
      //  导入的自定义字段，若有渲染，没有渲染系统字段
      getCustomFileds(this.sid,this.module).then(res=>{
        if(res.status){
          if(res.data.length!=0){
            this.formFileds(res)
          }else{
            //系统所有字段
            exportItem(this.module).then(res => {
              if (res.status) {
                this.formFileds(res)
              }
            })
          }
        }
      })
      },
      //格式化数据
      formFileds(res){
        console.log("准考证字段",res)
        let allData = res.data
        this.sysFields = []
        allData.forEach(item => {
          this.sysFields.push({
            zddm:item.zddm?item.zddm: item.dbColumnName,
            zdmc:item.zdmc?item.zdmc: item.columnNameZh,
            txlx: "input",
            sfbt: 1,
            detail: item,
            zdbl: 50
          })

        })
        this.list1 = JSON.parse(JSON.stringify(this.sysFields))
      },

      chooseEle(e) {
        this.chooseId = e;
        this.chooseId.zdymc = e.zdymc ? e.zdymc : e.zdmc
        this.$forceUpdate()
      },
      wChange() {
        this.$forceUpdate();
      },
      // 根据中间的高度动态显示两侧高度
      changeCenter(num = 0) {
        let currentHeight = this.$refs.main.$el.clientHeight
        this.minHeight = currentHeight + num
      },
      changeChecked() {
        this.isSaveMold = !this.isSaveMold
      },
      // 提交创建好的模板
      success() {
        this.listChoose = [];
        this.list2.forEach((item) => {
          item["zdfl"] = 1;
          this.listChoose.push(item);
        });
        this.list3.forEach((item) => {
          item["zdfl"] = 2;
          this.listChoose.push(item);
        });
        this.list4.forEach((item) => {
          item["zdfl"] = 3;
          this.listChoose.push(item);
        });
        let mold = this.listChoose

        if (mold && mold.length > 0) {

          if (this.examForm.zkzmc) {

            // 判断修改
            if (this.mid) {
              // 上传背景图直接触发修改
              if (this.fileUp || this.isChangeHxbj || this.isChangeName || this.isDraggle || this.isImport || this
                .isChange) {
                if (this.isSaveMold) {
                  this.saveAsTemplate()

                } else {
                  this.editTemp()
                }
                return
              } else {
                // 未做操作
                if (this.isSaveMold) {
                  this.saveAsTemplate()

                } else {
                  this.updateZkzk()
                }

              }
            } else {
              if (this.isSaveMold) {
                this.saveAsTemplate()
                return
              }
              this.createTemp()
            }
          } else {
            this.$message({
              type: "warning",
              message: "请先命名该准考证！",
            });
            return
          }
        } else {
          this.$message({
            type: "warning",
            message: "请拖动组件创建表单",
          });
        }

      },
      // 保存为模板
      saveAsTemplate() {
        this.checkMbmc()
        if (this.mbmc) {
          if (!this.hasMbmc) {
            this.createTemp()

          } else {
            this.$message({
              type: "warning",
              message: "数据库存在相同模板名称，请重命名！",
            });
          }
        } else {
          this.$message({
            type: "warning",
            message: "请先命名该模板名称！",
          });
        }
      },
      // 创建准考证样式模板
      createTemp() {

        let formData = {}
        formData.mbmc = this.mbmc,
          formData.mblx = "准考证"
        formData.sfhxbj = this.sfhxbj
        formData.sfxsewm = this.sfxsewm
        // formData.sfdycns = this.sfdycns,
          formData.cjbjwj = this.imgId,
          formData.printTemplateFieldBeanList = this.listChoose
        if (this.isShowZp == false) {
          formData.sfxszp = 0
        } else {
          formData.sfxszp = 1
        }
        // 保存模板
        saveScorTemplate(formData).then(res => {
          if (res.status) {
            if (this.mid) {
              this.editTemp()
              this.updateZkzk(this.mid)
            } else {
              if (!this.isImport) {
                let mbh = res.data.mbh
                this.updateZkzk(mbh)
              } else {
                formData.mbmc = ""
                saveScorTemplate(formData).then(res1 => {
                  if (res1.status) {
                    let mbh = res1.data.mbh
                    this.updateZkzk(mbh)
                  }
                })
              }


            }
            this.$message({
              type: "success",
              message: "模板已成功保存",
            });
          }
        })

      },

      // 输入时看有没有相同模板名称
      checkMbmc() {
        if (this.moudleList.length && this.mbmc) {
          let sameObj = this.moudleList.find(k => {
            return k.mbmc == this.mbmc
          })
          if (sameObj) {
            this.hasMbmc = true
          } else {
            this.hasMbmc = false
          }
        }

      },


      // 修改准考证样式模板
      editTemp() {
        let formData = {}
        // formData.mbmc = this.examForm.mbmc,
        formData.mid = this.mid,
          formData.mblx = "准考证",
          formData.sfhxbj = this.sfhxbj,
          formData.sfxsewm = this.sfxsewm,
          formData.cjbjwj = this.imgId,
          formData.printTemplateFieldBeanList = this.listChoose
        if (this.isShowZp == false) {
          formData.sfxszp = 0
        } else {
          formData.sfxszp = 1
        }
        // 保存模板
        editTemplate(formData).then(res => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "模板已成功修改",
            });
            if (this.mid) {
              this.updateZkzk(this.mid)
            } else {
              let mbh = res.data.mbh
              this.updateZkzk(mbh)
            }



          }
        })
      },
      // 修改准考证库
      updateZkzk(params) {
        var _this = this
        if (this.examForm.zkzmc) {
          let formData = JSON.parse(JSON.stringify(this.examForm))
          formData.sid = this.examForm.sid,
          formData.sfdycns = this.sfdycns,

            formData.mbh = params

          editZkz(formData).then(res => {
            if (res.status) {
              _this.$refs.header.getUrl('/admin/printCardImportCheck')
            }
          })
        } else {
          this.$message({
            type: "warning",
            message: "请先输入准考证名称！",
          });
        }


      },
      clone(e) {
        this.chooseId = JSON.parse(JSON.stringify(e))
        this.isDraggle = true
        return this.chooseId;
      },
      pullFunction() {
        return this.controlOnStart ? "clone" : true;
      },
      start({
        originalEvent
      }) {
        this.controlOnStart = originalEvent.ctrlKey;
      },
      addEnd(e) {
        if (this.$refs["list1Container"].$el.clientHeight > 170) {
          var a = this.list2[e.newIndex];
          this.list2.splice(e.newIndex, 1);
          this.list1.push(a);
          this.$message({
            type: "warning",
            message: "该容器已满，请将组件拖到下面的容器中！",
          });
        }
        this.changeCenter()
        this.isDraggle = true

      },

      // 提交创建好的模板
      getTemplateList(sMid) {
        this.list1 = []
        this.list2 = []
        this.list3 = []
        this.list4 = []
        let func;
        let param;
        var data
        if (sMid) {
          func = getTemplateInfo
          param = sMid

          func(param).then(res1 => {
            if (res1.status) {
              // this.mbmc = res1.data.mbmc ? res1.data.mbmc : ""
              this.imgId = res1.data.cjbjwj ? res1.data.cjbjwj : ""
              this.getfileUrl()
              data = this.sysFields.filter(item => {
                let flag = true;
                for (let i in res1.data.printTemplateFieldBeanList) {
                  if (res1.data.printTemplateFieldBeanList[i].zddm == item.zddm) {
                    res1.data.printTemplateFieldBeanList[i].sfxtzd = item.sfxtzd
                    flag = false;
                  }
                }
                return flag;
              })
              if (!res1.data.sfxszp) {
                this.isShowZp = false
                this.$nextTick(() => {
                  this.hiddenZp()
                })
              } else {
                this.isShowZp = true
                this.hiddenZp()
              }
              this.sfhxbj = res1.data.sfhxbj ? res1.data.sfhxbj : false
              this.sfxsewm = res1.data.sfxsewm ? res1.data.sfxsewm : false

              res1.data.printTemplateFieldBeanList.forEach(item => {
                if (this.examForm.zpysyq != 4) {
                  if (item.zdfl == 1) {
                    this.list2.push(item)
                  } else if (item.zdfl == 2) {
                    this.list3.push(item)
                  } else if (item.zdfl == 3) {
                    this.list4.push(item)
                  }
                } else {
                  this.list3.push(item)
                }
              })
              this.list1 = data
              return
            }

          })
        }
        this.list1 = data
      },

      // 模板下拉
      getMould() {
        TemplateEasyList({
          mblx: "准考证"
        }).then(res => {
          if (res.status) {
            this.moudleList = res.data
          }
        })
      },
      // 模板导入
      importMould(mid) {
        this.$confirm("确定导入准考证模板，此操作可能会覆盖您当前的表单样式, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.isImport = true
          this.hasMbmc = true
          this.getTemplateList(mid)

        });
      },
      // 准考证库详情
      getDetail(sid) {
        getZkzInfo(sid).then(res => {
          if (res.status) {
            this.examForm = res.data
            this.mid = res.data.mbh //用来渲染模板
            // this.styId = res.data.mbh ? res.data.mbh : res.data.yzmbh //用来默认选中当前模板，可以预览
            this.title2 = res.data.ksmc ? res.data.ksmc : ""
            let ksbmbh = res.data.ksbmbh
            let yzmbh = res.data.yzmbh
            // console.log(res.data.sfdycns);
            this.sfdycns = res.data.sfdycns ? res.data.sfdycns : 0

            if (this.mid) {
              this.getTemplateList(this.mid)
            } else if (yzmbh) {
              this.isImport = true
              this.hasMbmc = true
              this.checkMbmc()
              this.getTemplateList(yzmbh)
            }



            if (ksbmbh) {
              getExamDetails(ksbmbh).then(res => {
                if (res.status) {
                  this.examFormObj = res.data
                  this.flbm = res.data.flbm
                  if (this.examFormObj.zpysyq == 4) {
                    this.isShowZp = false
                    this.hiddenZp()
                  }

                }
              })
            }

          }
        })
      },
      // 拖动结束触发
      endFn() {
        this.isDraggle = true
      },

      isHidden() {
        this.isShowZp = !this.isShowZp
        this.isChange = true
        this.hiddenZp()
        this.$message({
          type: "success",
          message: "操作成功，若要预览请先保存表单！",
        });
      },
      // 显示隐藏照片样式设置
      hiddenZp() {
        if (this.isShowZp == false) {
          $(".guding-top").css("width", "99%")
          $(".guding-top").addClass("border-right")
          $(".guding-col").addClass("less-width")
          $(".guding-img").css("display", "none")
        } else {
          $(".guding-img").css("display", "flex")
          $(".guding-top").css("width", "80%")
          $(".guding-top").removeClass("border-right")
          $(".guding-col").removeClass("less-width")
        }

      },


      // 删除模板
      deleteMod(mid) {
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteModule(mid).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.styId = ""
              this.getMould()
            }
          })
        });

      },

      //模板预览
      mouldPreview() {
        let routeUrl = this.$router.resolve({
          path: "/admin/printCardPreview",
          query: {
            sid: this.sid,
            mid: this.styId,
            mbmc: this.styMbmc
          }
        });
        if (this.styId) {
          window.open(routeUrl.href, '_blank');
        } else {
          this.$message({
            type: "warning",
            message: "请选择需要预览的模板！",
          });
        }

      },
      // 背景预览
      backgroundPreview() {

        // let routeUrl = this.$router.resolve({
        //   path: "/admin/printCardPreview",
        //   query: {
        //     sid: this.sid,
        //     mid: this.mid,
        //     imgId: this.imgId,
        //   }
        // });
        // if (this.mid) {
        //   window.open(routeUrl.href, '_blank');
        // }
        if (this.fileUrl) {
          window.open(this.fileUrl, '_blank');
        } else {
          this.$message({
            type: "warning",
            message: "暂未设置背景图！",
          });
        }
      },

      uploadFile(file) {
        /** 上传背景文件 */
        var _this = this;
        file = file.raw;
        if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
          _this.uploadStatus = "loading";
          uploadFiles(_this.uploadModule, file).then(res => {
            if (res.status) {
              var file = res.data[0];
              if (file.status) {
                this.imgId = file.fileId;
                // this.fileUrl = file.path
                this.getfileUrl()
                _this.$message({
                  type: "success",
                  message: "背景上传成功,可以预览,如需保存操作,请提交表单！"
                });
                this.fileUp = true
              } else {
                _this.$message({
                  type: "error",
                  message: file.error
                });
              }
            }
            _this.uploadStatus = "";
          });
        }
      },
      getFileLimit() { // 获取文件上传限制
        getUploadLimit({
          module: this.uploadModule
        }).then(res => {
          if (res.status) {
            this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
            this.uploadLimit["size"] = res.data.sizeLimit;
          }
        });
      },
      getfileUrl() {
        if (this.imgId) {
          this.fileUrl = this.getFileUrl(this.imgId)
        }
      },
      getmbmc() {
        let val = this.styId
        let obj = this.moudleList.find(k => {
          return k.mid === val
        })
        this.styMbmc = obj.mbmc

      },
      // 切换基础模板
      addFun(str, sid, mc, dm) {
        this.examForm[mc] = str
        this.examForm[dm] = sid
      },

      changeHxbj() {
        this.isChangeHxbj = true
      },
      showRules(type) {
        if (type == "rule") {
          this.$refs.printRules.check('zkzsmmc', 'zkzsmh')
        } else {
          this.$refs.printRules.check('zkzgzcnmc', 'zkzgzcnh')
        }
      },
      // 自定义名称
      editName() {
        $("#zdName").removeClass("border-0")
        $("#zdName").addClass("nameEditable")
      },

      removeNameStyle() {
        $("#zdName").removeClass("nameEditable")
        $("#zdName").addClass("border-0")
      },
      // 清除已上传背景
      clearImg() {
        this.$confirm("此操作将清除已上传的背景图, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$message({
            type: "success",
            message: "清除成功!请提交表单进行保存",
          });
          this.imgId = ""
          this.isChange = true
        });
      }

    },

    mounted() {
      this.getFileds()
      if (this.sid) {
        this.getDetail(this.sid)
      }

      this.getMould()
      let that = this
      setTimeout(function() {
        that.changeCenter()
      }, 500)
      this.getFileLimit()
    },
    created() {
      this.sid = this.$route.query.sid
      this.UEditorConfig.initialFrameHeight = 300; //定义富文本高度
    },
  };
</script>
<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <printCardHeader ref="header" />
    <div class="card" style="min-height: 470px;">
      <div class="card-body">
        <div class="flexList">
          <el-select class=" mr-2 blueBorder" style="width: 300px;" placeholder="请选择准考证模板" v-model="styId" size="small"
            @change="getmbmc">
            <el-option :value="item.mid" v-for="item in moudleList" :key="item.value" :label="item.mbmc">
              {{item.mbmc}}
              <span class="float-right closeIcon" @click.stop="deleteMod(item.mid)"><i class="iconfont icon-guanbi"></i>
              </span>
            </el-option>
          </el-select>
          <div>
            <button type="button" class="btn btn-info h30 mr-2 flexList" @click="importMould(styId)"><i
                class="iconfont icon-mb-search mr-2 font-size-18"></i>导入</button>
          </div>
          <button type="button" class="btn btn btn-outline-info h30 mr-2" @click="mouldPreview">模板预览</button>
          <el-upload class="upload-demo mr-2" ref="upload" action="" :on-change="uploadFile" :show-file-list="false"
            :accept="uploadLimit.ext" :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">
              <span name="default" v-if="uploadStatus != 'loading'" style="font-size: 0.7rem;">上传背景</span>
              <span v-if="uploadStatus == 'loading'">上传中<i class="el-icon-loading"></i></span>
            </el-button>
            <el-tag type="danger" style="margin-left: 10px;">
              支持上传{{ uploadLimit.ext }}文件，且不超过{{ uploadLimit.size/1024/1024 }}M</el-tag>
          </el-upload>

          <button type="button" class="btn btn btn-outline-info h30 mr-2" @click="backgroundPreview">
            背景预览</button>
          <button type="button" class="btn btn btn-outline-info h30 mr-2" @click="isHidden"><span
              v-if="isShowZp">隐藏照片</span><span v-else>显示照片</span></button>
          <button type="button" class="btn btn btn-outline-info h30 mr-2" @click="clearImg">清除已上传背景</button>
        </div>
        <div class="outerCotent mt-3 ">
          <div class="style-set-box">
            <div class="style-top flexList w-100">
              <div class="col-lg-2 p-0 ">字段区域</div>
              <div class="col-lg-8 p-0">准考证样式</div>
              <div class="col-lg-2 p-0">参数设置</div>
            </div>
            <el-row>
              <el-col :span="4">
                <div class="list-group-item">
                  <div>

                    <input type="text" name="" id="" placeholder="请输入相关组件查询" value="" class="form-control mt-2 h30"
                      v-model="keyword" />
                  </div>
                </div>
                <draggable class="dragArea list-group style-left auto-scroll" :list="list1" :clone="clone"
                  :style="[{'height':minHeight-93+'px'}]" :group="{ name: 'people', pull: pullFunction }"
                  @start="start">
                  <div class="list-group-item" v-for="element in list1" :key="element.sid"
                    v-show="!keyword||element.zdmc.indexOf(keyword)>-1">
                    <div class="style-info flexList justify-content-between ">
                      <div class="line1 w-50" :title="element.zdmc ">{{ element.zdmc }}</div>
                      <div class="grey-time" v-if="element.txlx == 'input'"></div>
                      <div class="grey-time" v-if="element.txlx == 'select'">下拉框</div>
                      <div class="grey-time" v-if="element.txlx == 'switch'">开关</div>
                      <div class="grey-time" v-if="element.txlx == 'radio'">单选框</div>
                      <div class="grey-time" v-if="element.txlx == 'textarea'">文本域</div>
                      <div class="grey-time" v-if="element.txlx == 'date'">日期</div>
                      <div class="grey-time" v-if="element.txlx == 'local' || element.txlx == 'local1'">地区</div>
                      <div class="grey-time" v-if="element.txlx == 'selectEx'">级联下拉框</div>
                    </div>
                  </div>
                </draggable>
              </el-col>
              <el-col :span="16" class="style-right" ref='main' id="mians">
                <div v-if="!mid" class="text-danger text-center">当前暂无样式，请先创建并保存生成，否则将影响后续操作</div>
                <div class="w-99 flexList mt-2">
                  准考证规则名称：
                  <div class="line1 mr-2">{{examForm.zkzsmmc}}
                  </div>
                  <button type="button" class="btn btn btn-info h30 mr-2" @click="showRules('rule')">选择规则</button>

                </div>
                <div class="w-99 flexList mt-2">
                  准考证告知承诺：
                  <div class="line1 mr-2">{{examForm.zkzgzcnmc}}
                  </div>
                  <button type="button" class="btn btn btn-info h30 mr-2" @click="showRules('promise')">选择承诺</button>

                </div>
                <div class="w-99 flexList mt-2">
                  是否打印告知承诺：
                  <el-radio v-model="sfdycns" :label=1 class="mb-0">是</el-radio>
                  <el-radio v-model="sfdycns" :label=0 class="mb-0">否</el-radio>
                </div>
                <div class="w-99 flexList mt-2">
                  准考证打印样式：
                  <el-radio v-model="sfhxbj" value=false :label=false class="mb-0" @change="changeHxbj">竖版</el-radio>
                  <el-radio v-model="sfhxbj" value=true :label=true class="mb-0" @change="changeHxbj">横版</el-radio>
                </div>
                <div class="w-99 flexList mt-2">
                  是否显示考生二维码：
                  <el-radio v-model="sfxsewm" value=true :label=true class="mb-0" @change="changeHxbj">是</el-radio>
                  <el-radio v-model="sfxsewm" value=false :label=false class="mb-0" @change="changeHxbj">否</el-radio>
                </div>
                <div class=" w-99 mt-3"><textarea rows="2" cols="" class="cjd_title form-control"
                    v-model="examForm.zkzmc" placeholder="准考证名称"></textarea>
                </div>
                <div class="mt-3 exam-form">
                  <el-row style="" class="border-diff top-height">
                    <el-col class="guding-top">
                      <draggable ref="list1Container" class="msgContent  border-diff flexList fs-xs guding-col"
                        :list="list2" group="people" style="min-height: 40px" @add="addEnd"
                        :class="[list2.length?'d-border-bottom':'']" @end="endFn">
                        <div @click="chooseEle(element)" class="flexList msgLabel canmove  "
                          :class="['w' + element.zdbl,element.txlx == 'textarea'?'msgtextarea':'']"
                          v-for="element in list2" :key="element.sid">
                          <div class="msgName">
                            <span style="color: red"
                              v-if="element.sfbt">*</span>{{ element.zdymc?element.zdymc:element.zdmc }}
                          </div>
                          <div class="msgVal selectShow flexList">
                          </div>
                        </div>
                      </draggable>
                    </el-col>
                    <el-col class="guding-img">
                      <img :src="require('@/assets/images/person/person-none.png')" alt=""
                        style="width: 120px; height: 150px" />
                    </el-col>
                  </el-row>
                  <el-row>
                    <draggable style="min-height: 40px; width: 99%; flex-wrap: wrap" class="flexList fs-xs less-width"
                      :list="list3" @add="addEnd" group="people" :class="[list3.length?'d-border-bottom':'']"
                      @end="endFn">
                      <div @click="chooseEle(element)" class="flexList msgLabel canmove "
                        :class="['w' + element.zdbl,element.txlx == 'textarea'?'msgtextarea':'']"
                        v-for="element in list3" :key="element.sid">
                        <div class="msgName">
                          <span style="color: red"
                            v-if="element.sfbt">*</span>{{ element.zdymc?element.zdymc:element.zdmc }}
                        </div>
                        <div class="msgVal selectShow flexList">
                        </div>
                      </div>
                    </draggable>
                    <div class="center-table-title">
                      考试科目列表区
                    </div>
                    <draggable style="min-height: 40px; width: 99%;" class="flexList fs-xs less-width " :list="list4"
                      @add="addEnd" group="people" :class="[list4.length?'d-border-bottom':'']" @end="endFn">
                      <div @click="chooseEle(element)" class="flexList msgLabel canmove "
                        :class="[element.txlx == 'textarea'?'msgtextarea':'']" v-for="element in list4"
                        :key="element.sid">
                        <div class="msgName w-100">
                          {{ element.zdmc }}
                        </div>
                      </div>
                    </draggable>

                    <div class="w-99 ">
                      <div class="m-1">准考证注意事项</div>
                      <div class="content-mould">
                        <vue-ueditor-wrap v-model="examForm.zkzzysx" :config="UEditorConfig">
                        </vue-ueditor-wrap>
                      </div>

                      <!--  <textarea rows="4" cols="" class="notice w-100 form-control" v-model="examForm.zkzzysx"
                        placeholder="请填写"></textarea> -->
                    </div>
                  </el-row>

                </div>
              </el-col>
              <el-col :span="4" class="mould-right" :style="[{'height':minHeight+'px'}]">
                <div class="param-name"><i class="iconfont icon-riLine-team-line mr-2 font-size-18"></i><input
                    @click="editName" @change="isChangeName = true" @blur="removeNameStyle" id="zdName"
                    v-model="chooseId.zdymc" class="border-0 name-ed"></div>
                <!-- <div class="mt-3 exam-form">
                  <div class="param-box">
                    <div class="param-name">字段名称</div>
                    <div class="col-sm-10 p-0 flexList">
                      <input type="text" v-model="chooseId.zdymc">
                    </div>
                  </div>
                </div> -->
                <div class="mt-3 exam-form">
                  <div class="param-box">
                    <div class="param-name">字段比例</div>
                    <div class="col-sm-10 p-0 flexList">
                      <yz-select :dict="[
                   {name: '20%', value: '20'},
                   {name: '25%', value: '25'},
                   {name: '33%', value: '33'},
                   {name: '50%', value: '50'},
                    {name: '100%', value: '100'},
              ]" :bound="['zdbl']" class="w-100" v-model="chooseId" @change="$forceUpdate();isChangeName = true"
                        v-if="chooseId.zdfl!=3">
                      </yz-select>

                      <yz-select :dict="[
                   {name: '10%', value: '10'},
                   {name: '20%', value: '20'},
                   {name: '30%', value: '30'},
                   {name: '40%', value: '40'},
                   {name: '50%', value: '50'},
                   {name: '60%', value: '60'},
                   {name: '70%', value: '70'},
                   {name: '80%', value: '80'},
                   {name: '90%', value: '90'},
                    {name: '100%', value: '100'},
              ]" :bound="['zdbl']" class="w-100" v-model="chooseId" @change="$forceUpdate();isChangeName = true"
                        v-else></yz-select>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="flexList w-100 " style="height: 60px;">
            <div class="form-check  mr-3">
              <input class="form-check-input" type="checkbox" value id="defaultCheck1" v-model="isSaveMold"
                @click="changeChecked" />
              <label class="form-check-label" for="defaultCheck1">保存为模板</label>
            </div>
            <input type="text" name="" value="" class="col-lg-2 h30 form-control" v-model="mbmc" placeholder="命名模板名称"
              @blur="checkMbmc" />
            <div class="flexList" style="margin-left: 25px;">
              <button class="btn btn-info h35 mr-2 sub-btn" @click="success()">保存表单，进入下一步</button>
              <a href="javascript:history.back()"><button class="btn btn-secondary h35 mr-2 ">返回上一步</button></a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗开始 -->
    <PrintRules :flbm="flbm" @addFun="addFun" :examForm="examForm" ref="printRules"></PrintRules>
    <!-- 弹窗结束-->
  </Layout>
</template>
